import { createDirectus, staticToken, rest, readTranslations, readItem, readItems, readField, updateItem, updateItems, createItem, deleteItem, uploadFiles, updateFile, utilitySort} from '@directus/sdk';
import { EventEmitter } from 'events';

class SaasApp extends EventEmitter {
  constructor() {
    super();

    console.log('Initializing Directus SDK');
    this.authenticated = false;

    this.directus = createDirectus('https://saas.360productviewer.com/').with(staticToken('94sb6x9zFG9hVA9G2AXzqlkNxGXSDGQk')).with(rest());

    this._guilanguage = 'nl-NL';
    this._active_product_id = -1;
    this._categories = [];
    this._product = null;

    this._products = [];
    this._options = [];
    this._option_values = [];

    this._fields = [];
    this._translations = {};

    this.authenticateWithStaticToken();
    this.getTranslations();
  }

  set guilanguage(value) {
    this._guilanguage = value;
    this._emitChangeLanguage();
  }

  get guilanguage() {
    return this._guilanguage;
  }

  set translations(value) {
    this._translations = value;
  }

  get translations() {
    return this._translations;
  }

  set categories(value) {
    this._categories = value;
    this._emitChangeCategory();
  }

  get categories() {
    return this._categories;
  }

  set fields(value) {
    this._fields = value;
    this._emitChangeProduct();
  }

  get fields() {
    return this._fields;
  }

  set active_product_id(value) {
    this._active_product_id = value;
    this._emitChangeProduct();
    this._emitSetProductId();
  }

  get active_product_id() {
    return this._active_product_id;
  }

  set product(value) {
    this._product = value;
    this._emitChangeProduct();
  }

  get product() {
    return this._product;
  }

  set options(value) {
    this._options = value;
    this._emitChangeProduct();
    this._emitChangeOptions();
  }

  get options() {
    return this._options;
  }

  set option_values(value) {
    this._option_values = value;
    this._emitChangeProduct();
    this._emitChangeOptionValues();
  }

  get option_values() {
    return this._option_values;
  }

  set products(value) {
    this._products = value;
    this._emitChangeProduct();
  }

  get products() {
    return this._products;
  }

  _emitSetProductId() {
    this.emit('setProductId', this.active_product_id);
  }

  _emitChangeProduct() {
    this.emit('changeProducts', {
      product: this.product,
      options: this.options,
      option_values: this.option_values,
      products: this.products,
    });
  }

  _emitChangeOptions() {
    this.emit('changeOptions', this.options);
  }

  _emitChangeOptionValues() {
    this.emit('changeOptionValues', this.option_values);
  }

  _emitChangeTextures(textures) {
    this.emit('changeTextures', textures);
  }

  _emitChangeSwatches() {
    this.emit('changeSwatches', swatches);
  }

  _emitChangeCategory() {
    this.emit('changeCategories', {
      categories: this.categories,
    });
  }

  _emitChangeLanguage() {
    this.emit('changeLanguage', {
      guilanguage: this.guilanguage,
    });
  }

  _emitChangeDynamicSkus(dynamic_skus) {
    this.emit('changeDynamicSkus', dynamic_skus);
  }

  _emitChangeDynamicSkuRows(dynamic_sku_rows) {
    this.emit('changeDynamicSkuRows', dynamic_sku_rows);
  }

  _emitChangeDynamicSkuConfigs(dynamic_sku_configs) {
    this.emit('changeDynamicSkuConfigs', dynamic_sku_configs);
  }

  _emitChangeDynamicSkuConfigOptions(dynamic_sku_config_options) {
    this.emit('changeDynamicSkuConfigOptions', dynamic_sku_config_options);
  }

  _emitChangeDynamicSkuConfigOptionValues(dynamic_sku_config_option_values) {
    this.emit('changeDynamicSkuConfigOptionValues', dynamic_sku_config_option_values);
  }

  _emitChangeModels(models) {
    this.emit('changeModels', models);
  }
  
  async setActiveProductId(product_id) {
    this.active_product_id = product_id;
    return this.getItem('saas_products', this.active_product_id, ['*', 'translations.id', 'translations.languages_code', 'translations.name', 'translations.slug', 'translations.description', 'translations.short_description']).then((response) => {
      this.product = response;
    });    
  }

  async getCurrentUser() {
    await this.directus.users.me.read();
  }

  async authenticateWithStaticToken() {
      this.authenticated = true;
  }

  // TRANSLATIONS
  async getTranslations() {

    const result = await this.directus.request(readTranslations({}))
    .catch((error) => {
      console.log(error);
    });

    const translations = {};
    for (let i = 0; i < result.length; i++) {
      const translation = result[i];
      if (!Object.hasOwnProperty.call(translations, translation.language)) {
        translations[translation.language] = {};
      }
      translations[translation.language][translation.key] = translation.value;
    }

    this.translations = translations;
    
  }

  getTranslationValue(key, default_value) {
    if (!Object.hasOwnProperty.call(this.translations, this.guilanguage)) {
      return default_value;
    }
    if (!Object.hasOwnProperty.call(this.translations[this.guilanguage], key)) {
      return default_value;
    }
    return this.translations[this.guilanguage][key];
  }

  getLanguage() {
    return this.guilanguage;
  }

  setLanguage(key) {
    this.guilanguage = key;
  }

  //CATEGORIES
  /*
  async sortCategories(from_id, too_id) {
    await this.directus.utils
      .sort('saas_categories', from_id, too_id)
      .then((response) => {
        const from_index = this.categories.findIndex((category) => category.id === from_id);
        const too_index = this.categories.findIndex((category) => category.id === too_id);
        if (from_index === -1 || too_index === -1) return;
        const from = this.categories[from_index];
        const too = this.categories[too_index];
        this.categories[from_index] = too;
        this.categories[too_index] = from;

        let categories = this.categories;
        this.categories = this.sortCategoriesByParent(categories);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  */
  
  sortCategoriesByParent(categories) {
    const sorted_categories = categories.filter((category) => category.parent_id === null);
    //loop through sorted_categories
    sorted_categories.forEach((parent_category) => {
      parent_category.level = 0;
      parent_category.children = 0;
    });

    //remove sorted_categories from categories
    categories = categories.filter((category) => category.parent_id !== null);

    //sort categories by sort value
    categories.sort((a, b) => (a.sort > b.sort ? 1 : -1));

    while (categories.length > 0) {
      const child_category = categories.find((category) => sorted_categories.some((parent_category) => parent_category.id === category.parent_id));

      const p_cat = sorted_categories.find((parent_category) => parent_category.id === child_category.parent_id);
      child_category.level = p_cat.level + 1;
      child_category.children = 0;
      p_cat.children += 1;

      const index = sorted_categories.findIndex((parent_category) => child_category.parent_id === parent_category.id);
      sorted_categories.splice(index + 1, 0, child_category);
      categories = categories.filter((category) => category.id !== child_category.id);
    }
    return sorted_categories;
  }

  isProductInCategory(category_id, product_id) {
    const index = this.categories.findIndex((category) => category.id === category_id);
    return this.categories[index].products.some((category_product) => category_product.saas_products_id === product_id);
  }

  getLocalCategoryProductsValue(category_id, product_id) {
    const index = this.categories.findIndex((category) => category.id === category_id);
    const target_index = this.categories[index].products.findIndex((category_product) => category_product.saas_products_id === product_id);
    if (target_index === -1) {
      return false;
    }
    return this.categories[index].products[target_index].id;
  }

  //OPTIONS
  getLocalOptionById(id) {
    const index = this.options.findIndex((option) => option.id === id);
    return this.options[index];
  }

  getLocalOptionValueById(id) {
    const index = this.option_values.findIndex((option_value) => option_value.id === id);
    return this.option_values[index];
  }

  /*
  async getOptionValuesByOptionIdAndReplace(option_value_id) {
    await this.directus
      .items('saas_product_option_values')
      .readOne(option_value_id, { fields: ['*', 'dependencies.*', 'swatch_id.id', 'swatch_id.image.id', 'swatch_id.image.height', 'swatch_id.image.width', 'swatch_id.image.filename_disk', 'swatch_id.image.filename_download', 'translations.id', 'translations.languages_code', 'translations.name'] })
      .then((response) => {
        const index = this.option_values.findIndex((option_value) => option_value.id === option_value_id);
        this.option_values[index] = response;
        this._emitChangeProduct();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  */

  //FILES
  async createFile(form_data) {
    const result = await this.directus.request(uploadFiles(form_data)).catch((error) => {
      console.log(error);
      alert('File not uploaded');
    });
    return result;
  }

  updateItemFile(file_id, form_data) {
    return this.directus.request(updateFile(file_id, form_data)).catch((error) => {
      console.log(error);
      alert('File not uploaded');
    });
  }

  //SINGLE ITEM  
  getItem (collection, id, fields = ['*']) {
    return this.directus.request(readItem(collection, id, {
      fields: fields
    }))
      .catch((error) => {
        console.log(error);
      });
  }

  //COLLECTIONS
  async getCollectionCount(collection) {
    const result = await this.directus.request(readItems(collection, {
      filter: { status: { _neq: 'archived' } },
      aggregate: { countDistinct: 'id' }
    }))
    .catch((error) => {
      console.log(error);
    });
    return result[0].countDistinct;
  }

  getCollection(collection_name, page = 0, limit = -1, sort = ['sort'], fields = ['*'], filter = {}) {
    return this.directus.request(readItems(collection_name, {
      limit: limit,
      page: page,
      sort: sort,
      fields: fields,
      filter: filter
    }))     
    .catch((error) => {
      console.log(error);
    });
  }  

  async createItem(collection_name, item_data) {
    const item = await this.directus.request(createItem(collection_name, item_data))
      .catch((error) => {
        console.log(error);
    });
    return item;
  }

  updateItemField(collection_name, id, field, value, query = {}) {
    return this.directus.request(updateItem(collection_name, id, {
      [field]: value,
    }, query))
    .catch((error) => {
      console.log(error);
    }); 
  }

  deleteItem(collection_name, id) {
    return this.directus.request(deleteItem(collection_name, id))
    .catch((error) => {
      console.log(error);
    });
  }

  sortItem(collection_name, from_id, too_id) {
    return this.directus.request(utilitySort(collection_name, from_id, too_id))
    .catch((error) => {
      console.log(error);
    });
  }
  
  getFullImage(id, filename_disk) {
    if (id == null || id == undefined || id == '') {
      return '';
    }

    if (filename_disk == null || filename_disk == undefined || filename_disk == '') {
      return '';
    }
    return 'https://saas.360productviewer.com/assets/' + id + '/'+ filename_disk;
  }


  getThumbnail(id) {
    if (id == null || id == undefined || id == '') {
      return '';
    }
    //const result = await this.directus.request(readAssetRaw(id, {key: 'thumb64'})); voor auth
    return 'https://saas.360productviewer.com/assets/' + id + '/thumbnail.png?key=thumb64&ts=' + Date.now();
  }

  getThumbImage48(id) {
    if (id == null || id == undefined || id == '') {
      return '';
    }
    //const result = await this.directus.request(readAssetRaw(id, {key: 'thumb64'})); voor auth
    return 'https://saas.360productviewer.com/assets/' + id + '/thumbnail.png?key=thumb48&ts=' + Date.now();
  }

  getThumbImage128(id) {
    if (id == null || id == undefined || id == '') {
      return '';
    }
    //const result = await this.directus.request(readAssetRaw(id, {key: 'thumb64'})); voor auth
    return 'https://saas.360productviewer.com/assets/' + id + '/thumbnail.png?key=thumb128&ts=' + Date.now();
  }

  //DEPENDENCIES

  removeLocalOptionValueDependencyById(id, dependency_ids) {
    const index = this.option_values.findIndex((option_value) => option_value.id === id);
    if (index === -1) return;
    const dependencies = this.option_values[index].dependencies.filter((dependency) => dependency_ids.includes(dependency.id));
    this.option_values[index].dependencies = dependencies;
    this._emitChangeProduct();
    this._emitChangeOptionValues();
  }

  getLocalOptionValueDependencyIdById(id, target_id) {
    const index = this.option_values.findIndex((option_value) => option_value.id === id);
    if (index === -1) return -1;
    const target_index = this.option_values[index].dependencies.findIndex((dependency) => dependency.related_saas_product_option_values_id === target_id);
    return this.option_values[index].dependencies[target_index].id;
  }

  getLocalOptionValueDependencyIndexById(id, target_id) {
    const index = this.option_values.findIndex((option_value) => option_value.id === id);
    if (index === -1) return -1;
    const target_index = this.option_values[index].dependencies.findIndex((dependency) => dependency.related_saas_product_option_values_id === target_id);
    return target_index;
  }

  calculateAllDependentOptionsAndValues(current_selected_options) {
    const visible_dependent_option_values = {};
    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      const has_dependency = this.hasDependency(option.id);

      for (let j = 0; j < this.option_values.length; j++) {
        const option_value = this.option_values[j];
        if (option_value.option_id == option.id && option_value.is_disabled != 1) {
          if (!Object.hasOwnProperty.call(visible_dependent_option_values, option.id)) {
            visible_dependent_option_values[option.id] = [];
          }
          visible_dependent_option_values[option.id].push(option_value.id);
        }
      }
      if (!has_dependency) {
        continue;
      }
      visible_dependent_option_values[option.id] = this.getVisibleOptionValues(current_selected_options, visible_dependent_option_values[option.id]);
    }

    for (const [option_id, selected_option_value_array] of Object.entries(current_selected_options)) {
      if (selected_option_value_array.length === 0) {
        continue;
      }
      for (let i = selected_option_value_array.length - 1; i >= 0; i--) {
        const selected_option_value = selected_option_value_array[i];
        if (Object.hasOwnProperty.call(visible_dependent_option_values, option_id)) {
          const index = visible_dependent_option_values[option_id].findIndex((option_value_id) => option_value_id === selected_option_value);
          if (index === -1) {
            current_selected_options[option_id].splice(i, 1);
          }
        }
      }
    }

    //While loop?
    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      const has_dependency = this.hasDependency(option.id);
      if (!has_dependency) {
        continue;
      }
      visible_dependent_option_values[option.id] = this.getVisibleOptionValues(current_selected_options, visible_dependent_option_values[option.id]);
    }

    return [current_selected_options, visible_dependent_option_values];
  }

  hasDependency(option_id) {
    const index = this.options.findIndex((option) => option.id === option_id);
    if (this.options[index].dependency == 'and' || this.options[index].dependency == 'or') {
      return true;
    }
    return false;
  }

  getVisibleOptionValues(selected_option_values, option_value_ids) {
    const active_option_ids = [];
    for (const [key, selected_option_value_array] of Object.entries(selected_option_values)) {
      if (selected_option_value_array.length === 0) {
        continue;
      }

      for (let i = 0; i < selected_option_value_array.length; i++) {
        const selected_option_value = selected_option_value_array[i];
        const index = this.option_values.findIndex((option_value) => option_value.id === selected_option_value);
        if (index === -1) {
          continue;
        }
        for (let j = 0; j < this.option_values[index].dependencies.length; j++) {
          const dependency = this.option_values[index].dependencies[j];
          if (option_value_ids.includes(dependency.related_saas_product_option_values_id)) {
            active_option_ids.push(dependency.related_saas_product_option_values_id);
          }
        }
      }
    }

    if (active_option_ids.length === 0) {
      return [];
    }
    return active_option_ids;
  }

  //FIELDS
  async getSelectableOptionFieldValues() {

    const saas_product_options_type = await this.directus.request(readField('saas_product_options', 'type'));
    const saas_product_options_dependency = await this.directus.request(readField('saas_product_options', 'dependency'));
    const saas_product_options_is_required = await this.directus.request(readField('saas_product_options', 'is_required'));
    const saas_product_options_gui_hide = await this.directus.request(readField('saas_product_options', 'gui_hide'));
    const saas_product_option_values_is_default = await this.directus.request(readField('saas_product_option_values', 'is_default'));
    const saas_product_option_values_is_disabled = await this.directus.request(readField('saas_product_option_values', 'is_disabled'));

    this.fields = [];
    this.fields.push(saas_product_options_type);
    this.fields.push(saas_product_options_dependency);
    this.fields.push(saas_product_options_is_required);
    this.fields.push(saas_product_options_gui_hide);
    this.fields.push(saas_product_option_values_is_default);
    this.fields.push(saas_product_option_values_is_disabled);
  }

  getFieldValues(collection, field) {
    const filterd_collection = this.fields.filter((item) => item.collection === collection);
    const filterd_fields = filterd_collection.filter((item) => item.field === field);

    if (filterd_fields.length > 0) {
      if (filterd_fields[0].meta.interface == 'select-dropdown') {
        return filterd_fields[0].meta.options.choices;
      }

      if (filterd_fields[0].meta.interface == 'boolean') {
        return [
          {
            text: 'No',
            value: 0,
          },
          {
            text: 'Yes',
            value: 1,
          },
        ];
      }
    }
    return [];
  }

  //ROUTING
  goBack($from) {
    switch ($from) {
      case 'categories':
        this.goToHome();
        break;
      case 'product':
        this.goToProductsPage();
        break;
      case 'products':
        this.goToHome();
        break;
      case 'swatches':
        this.goToHome();
        break;
    }
  }

  goToHome() {
    window.location.href = '/';
  }

  goToCategoriesPage() {
    window.location.href = '/categories';
  }

  goToProductsPage() {
    window.location.href = '/products';
  }

  goToProductPage(id) {
    window.location.href = '/products/' + id;
  }

  createHexColorImage(hex_color) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = 64;
    canvas.width = 64;
    ctx.fillStyle = hex_color;
    ctx.fillRect(0, 0, 64, 64);
    return canvas.toDataURL();
  }

  //create random uuid
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line no-mixed-operators
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line no-mixed-operators,eqeqeq
      const v = c == 'x' ? r : (r & 0x3) | 0x8;
      // eslint-disable-next-line eqeqeq
      return v.toString(16);
    });
  }

  //https://codepen.io/dulldrums/pen/RqVrRr
  toHex(buffer) {
    let hexCodes = [];
    let view = new DataView(buffer);
    for (let i = 0; i < view.byteLength; i += 4) {
      let value = view.getUint32(i);
      let stringValue = value.toString(16);
      let padding = '00000000';
      let paddedValue = (padding + stringValue).slice(-padding.length);
      hexCodes.push(paddedValue);
    }
    return hexCodes.join('');
  }

}

export default new SaasApp();
