
import saasApp from "../../routes/app.js";

<div class="antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900">  
  
    <div class="max-w-8xl px-4 sm:px-6 md:px-8">
        <${input.renderBody}/>
    </div>

</div>


class {
    onCreate() {
        this.state = {};
    }

    onInput(input) {}

    goToCategoriesPage() {
        saasApp.goToCategoriesPage();
    }   

    goToProducstPage() {
        saasApp.goToProductsPage();
    }   

    goToTemplatesPage() {
        window.location.href = "/templates";
    }   

    goToSwatchesPage() {
        window.location.href = "/swatches";
    }

    goToModelsPage() {
        window.location.href = "/models";
    }

    goToMaterialsPage() {
        window.location.href = "/materials";
    }

    goToTexturesPage() {
        window.location.href = "/textures";
    }


}